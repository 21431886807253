export default [{
    id: 2,
    title: "Premium Package",
    description: "Premium package for LLC setup",
    price: "299",
  },
  {
    id: 3,
    title: "Standard Package",
    description: "Standard package for LLC setup",
    price: "199",
  },
  {
    id: 4,
    title: "Basic Package",
    description: "Basic package for LLC setup",
    price: "99",
  }
]
